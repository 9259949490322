<template>
	<section class="invoice-preview-wrapper">
		<b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
			<b-card class="invoice-actions" no-body>
				<b-card-body>
					<b-row>
						<b-col cols="12" md="3">
							<label for="date_start">Fecha Inicio</label>
							<flat-pickr id="date_start" v-model="filter.start" class="form-control"
								:config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
						</b-col>
						<b-col cols="12" md="3">
							<label for="date_start">Fecha Fin</label>
							<flat-pickr id="date_start" v-model="filter.end" class="form-control"
								:config="{ dateFormat: 'Y-m-d' }" placeholder="DD/MM/YYYY" />
						</b-col>

						<b-col cols="12" md="3">
							<div class="demo-inline-spacing">
								<b-button size="sm" @click="getPrices" variant="primary"
									v-ripple.400="'rgba(255, 255, 255, 0.15)'">
									<feather-icon icon="SearchIcon" />
									Buscar
								</b-button>

								<b-button size="sm" v-if="filterPrices.length > 0" @click="printReport" variant="secondary"
									v-ripple.400="'rgba(255, 255, 255, 0.15)'">
									<feather-icon icon="PrinterIcon" />
									Imprimir
								</b-button>
							</div>
						</b-col>
					</b-row>
				</b-card-body>
			</b-card>

			<b-card v-if="items.length > 0" no-body class="mb-0">
				<div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
					:class="`${items.length > 10 ? 'b-table-sticky-header' : ''}`">
					<table ref="articlesTable"
						class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
						<thead>
							<tr>
								<th style="font-size: 10px!important;">Clave</th>
								<th style="font-size: 10px!important;">Articulo</th>
								<th style="font-size: 10px!important;" v-for="(col, index) in columns" :key="index">{{ col }}</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(item, index) in items" :key="index">
								<td>{{ item.clave_Articulo }}</td>
								<td>{{ item.articulo }}</td>
								<td v-for="col in columns" :key="col">
									<!-- Si el valor de la columna no está definido (null o undefined), muestra 0 -->
									{{ item[col] !== undefined ? (item[col] > 0 ? formatCurrency(item[col]) : '$0.00') :'$0.00' }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-card>
		</b-overlay>
	</section>
</template>

<script>
import {
	BCard,
	BCardHeader,
	BCardText,
	BCardBody,
	BTable,
	BTableLite,
	BOverlay,
	BSpinner,
	BRow,
	BCol,
	BFormInput,
	BButton,
	BInputGroup,
	BInputGroupPrepend,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'

import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import { AgGridVue } from "ag-grid-vue"; // AG Grid Component
import moment from 'moment';

import PricesArticlesProvider from '@/providers/PricesArticles';
const PricesArticlesResource = new PricesArticlesProvider()

export default {
	components: {
		BCard,
		BCardBody,
		BCardHeader,
		BCardText,
		BTable,
		BTableLite,
		BOverlay,
		BSpinner,
		BRow,
		BCol,
		BButton,
		BFormInput,
		BInputGroup,
		BInputGroupPrepend,

		AgGridVue, // Add AG Grid Vue3 component

		//
		flatPickr,
		vSelect,
	},
	directives: {
		Ripple,
	},
	data() {
		return {
			themeClass: "ag-theme-quartz",
			defaultColDef: {
				editable: false,
				filter: true,
			},
			sideBar: null,
		}
	},
	created() {
		this.sideBar = 'columns';
	},
	methods: {

	},
	setup() {

		const loading = ref(false)
		const prices = ref([])
		const search = ref(null)

		const filter = ref({
			start: moment().startOf('month').format('YYYY-MM-DD'),
			end: moment().format('YYYY-MM-DD'),
		})

		// Column Definitions: Defines the columns to be displayed.
		const columns = ref([]);
		const items = ref([])

		const getPrices = async () => {

			columns.value = []
			items.value = []

			const query = {
				rolClaveArtId: 17,
				fechaInicio: filter.value.start,
				fechaFinal: filter.value.end,
			}

			loading.value = true
			const { data } = await PricesArticlesResource.GetPriceListReport(query)
			loading.value = false

			if (data.isSuccesful) {

				if (data.data.length > 0) {
					// const columns = data.data.map(item => item.lista)

					// // Paso 1: Obtener todas las posibles opciones de "lista"
					// const opcionesLista = [...new Set(columns)];

					// console.log(opcionesLista)

					// // // Paso 2: Construir un nuevo array de datos transpuesto
					// // const datosTranspuestos = [];
					// // data.data.forEach(item => {
					// //   const nuevoItem = {
					// //     clave_Articulo: item.clave_Articulo,
					// //     articulo: item.articulo
					// //   };
					// //   opcionesLista.forEach(opcion => {
					// //     nuevoItem[opcion] = item.lista === opcion ? item.precio : 0;
					// //   });
					// //   datosTranspuestos.push(nuevoItem);
					// // });

					// // // Obtenemos los nombres de las columnas (excepto 'clave_Articulo' y 'articulo')
					// // columns.value = Object.keys(datosTranspuestos[0]).filter(
					// //   columna => columna !== "clave_Articulo" && columna !== "articulo"
					// // );

					// // // Establecemos los datos de las filas
					// // items.value = datosTranspuestos;
					// // console.table(datosTranspuestos)




					// Paso 1: Obtener todas las posibles opciones de "lista"
					const opcionesLista = [...new Set(data.data.map(item => item.lista))];

					// Paso 2: Construir un objeto para almacenar temporalmente los precios por artículo
					const preciosPorArticulo = {};
					data.data.forEach(item => {
						const claveArticulo = item.clave_Articulo;
						if (!preciosPorArticulo[claveArticulo]) {
							preciosPorArticulo[claveArticulo] = {
								clave_Articulo: claveArticulo,
								articulo: item.articulo
							};
							// Inicializar precios en cero para todas las opciones de lista
							opcionesLista.forEach(opcion => {
								preciosPorArticulo[claveArticulo][opcion] = 0;
							});
						}
						// Asignar el precio correspondiente a la opción de lista adecuada
						preciosPorArticulo[claveArticulo][item.lista] = item.precio;
					});

					// Paso 3: Convertir el objeto en un array de valores (una fila por artículo)
					const datosTranspuestos = Object.values(preciosPorArticulo);

					// Paso 4: Obtener los nombres de las columnas (opciones de lista)
					columns.value = opcionesLista;

					// Paso 5: Establecer los datos de las filas
					items.value = datosTranspuestos;

					console.log(datosTranspuestos)
				} else {
					helper.danger(`No hay registros.`)
				}
			} else {
				helper.danger(data.message)
			}
		}

		const filterPrices = computed(() => {
			let result = search.value ? items.value.filter(item =>
				item.clave_Articulo?.toLowerCase().includes(search.value.toLowerCase()) ||
				item.Articulo.toLowerCase().includes(search.value.toLowerCase())
			) : items.value;
			return result;
		})

		const printReport = () => {
			window.print()
		}

		onMounted(async () => {
			// await getPrices()
		})

		return {
			search,
			loading,
			columns,
			items,
			prices,
			filter,

			//Computed
			filterPrices,

			//Methods
			getPrices,
			printReport,
		};
	}
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

	// Global Styles
	body {
		background-color: transparent !important;
		font-size: 8pt !important;
	}

	nav.header-navbar {
		display: none;
	}

	.main-menu {
		display: none;
	}

	.header-navbar-shadow {
		display: none !important;
	}

	.content.app-content {
		margin-left: 0;
		padding-top: 2rem !important;
	}

	footer.footer {
		display: none;
	}

	.card {
		background-color: transparent;
		box-shadow: none;
	}

	.customizer-toggle {
		display: none !important;
	}

	// Invoice Specific Styles
	.invoice-preview-wrapper {



		// Action Right Col
		.invoice-actions {
			display: none;
		}
	}
}
</style>


<style>
.b-table-sticky-header {
	overflow-y: auto;
	max-height: calc(100vh - 120px) !important;
}
</style>